import { NEWS_ORIGIN_LIST, ONLINE_HOST, OSS_HOST } from '@/store/constants';
import { MoDate } from '@mobro/libs';

/** 新闻文章名称计算 */
export function getTodayDate(): Date {
    const [y, m, d, h] = JSON.parse(MoDate.format('[YYYY,M,D,h]', Date.now())).map((s: string) => Number(s));
    return new Date(y, m - 1, h > 17 && h < 24 ? d + 1 : d);
}

export function getTodayNewsName(): string {
    const date = MoDate.format('YYYYMMDD', getTodayDate());
    return date;
}

export function getTodayNewsDate(): string {
    return MoDate.format('YYYY-MM-DD', getTodayDate());
}

export function date2name(dateStr: string): string {
    return dateStr.replace(/-/g, '');
}

export function getRandomOriginNews(): string {
    const tmp = NEWS_ORIGIN_LIST.reduce((res: string[], [cur, num]: [string, number]) => {
        return res.concat(new Array(num).fill(cur));
    }, []);

    const index = Math.floor(Math.random() * tmp.length);

    return tmp[index];
}

export function getOssUrl(path: string): string {
    path = /^https?:\/\/\w*(?:\.\w*)+(\/.*)$/.exec(path)?.[1] || path;
    path = path[0] === '/' ? path : `/${path}`;
    return `${OSS_HOST}${path}`;
}

export function getRemoveOssPrefixPath(url: string): string {
    let [, path] = /^https?:\/\/\w*(?:\.\w*)+(\/.*)$/.exec(url) || ['', url];
    path = path[0] === '/' ? path : `/${path}`;
    return path;
}

// 前补位
export function padStrat(val: number | string = '', count = 2, placeholder = '0'): string {
    return String.prototype.padStart.call(val, count, placeholder);
}

/**
 * 处理其他人的文章内容
 * @param text 要处理的文本
 * @param series 需要被替换的其他人的公众号名称
 * @param newSeries 我们自己的公众号名称
 * @param count 返回多少条
 * @returns 处理后的数据
 */
export function processText(
    text: string,
    series = [
        '看个报',
        '零点简报',
        '简报微刊',
        '早读看天下',
        '早读简报',
        '今日简报之家',
        '每天60秒读懂世界简报',
        '每天3分钟读懂世界',
        '每日微语报',
        '每日早上好祝福语',
        '每日资讯简报',
        '365资讯简报',
        '早安日签简报',
        '小叮读报',
        '香港租车跨境接送',
    ],
    newSeries = '今个儿简讯',
    count = 20,
): string {
    // 将文本按照回车符分隔成数组
    const lines = text.split('\n').filter(s => s);

    // 替换series中的每个元素为newSeries
    for (let i = 0; i < lines.length; i++) {
        for (let j = 0; j < series.length; j++) {
            lines[i] = lines[i].replace(new RegExp(series[j], 'g'), newSeries);
        }

        // 检查并替换以特定格式开头的行
        if (/^\d+(\.|,|、|\s)/.test(lines[i])) {
            lines[i] = '## ' + lines[i].slice(lines[i].match(/^\d+(\.|,|、|\s)/)?.[0]?.length || 0).trim();
        }

        // 替换句子末尾的“。”、“；”或“.”为“。”
        lines[i] = lines[i].replace(/[.;]$|[\u3002\uFF1B]$/, '。');
    }

    // 随机打乱数组
    for (let i = lines.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [lines[i], lines[j]] = [lines[j], lines[i]];
    }

    // 筛选出包含newSeries的行和不包含newSeries的行
    let withNewSeries = lines.filter(line => line.includes(newSeries));
    const withoutNewSeries = lines.filter(line => !line.includes(newSeries));

    // 最多保留一条包含newSeries的行
    if (withNewSeries.length > 0) {
        withNewSeries = [withNewSeries[0]];
    }

    // 计算中间位置
    const middleIndex = Math.floor(count / 2);

    // 合并结果并取前count条
    let result = [];
    if (withNewSeries.length > 0) {
        result = [
            ...withoutNewSeries.slice(0, middleIndex),
            withNewSeries[0],
            ...withoutNewSeries.slice(middleIndex, count - 1),
        ];
    } else {
        result = withoutNewSeries.slice(0, count);
    }

    // return result.join('\n');
    return `以## 开头的为一条内容，请将以下内容意思相近的去除掉，然后返回给我，且不需要将我发给你的给我把格式转掉。

${result.join('\n')}`;
}

/**
 * 解析地址
 * @param url
 * @returns
 */
export function parseURL(url: string): {
    protocol: string;
    hostname: string;
    pathname: string;
    search: string;
    origin: string;
} {
    // 创建一个 <a> 元素，利用浏览器内置的 URL 解析功能
    const parser = document.createElement('a');
    parser.href = url;

    // 提取协议、域名、路径和参数
    const protocol = parser.protocol; // 协议（包含冒号）
    const hostname = parser.hostname; // 域名
    const pathname = parser.pathname; // 路径
    const search = parser.search; // 参数（包含问号）
    const origin = parser.origin;

    // 返回解析结果
    return {
        origin,
        protocol,
        hostname,
        pathname,
        search,
    };
}

/**
 * 代理图片地址
 * @param picture 原地址
 * @returns
 */
export function proxyImageUrl(picture: string): string {
    const { origin, pathname, search } = parseURL(picture);
    const newPictureUrl = `${ONLINE_HOST}/api/common/file/py${pathname}${search}${
        search ? '&' : '?'
    }originalHost=${origin}`;

    return newPictureUrl;
}
