
import { getLunarDate } from '@/libs/getLunar';
import { date2name } from '@/libs/utils';
import { copySelectElementContents } from '@mobro/libs';
import { Iobject } from '@mobro/libs/es/type';
import { IRequestMethod, request } from '@mobro/request';
import { Message } from 'element-ui';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAutoNewsParams, IPagination, IAutoCreateSentenceItem } from '../type';

type IEditing = IAutoCreateSentenceItem & { tmpValue: string };
@Component({})
export default class ImgJudge extends Vue {
    @Prop() newsCount!: number;
    @Prop() newsName!: string;

    private pagination: IPagination = { current: 0, pageSize: 20, total: 0 };
    private autoNewsList: IAutoCreateSentenceItem[] = [];
    private showEditDialog = false;
    private editing: IEditing = { tmpValue: '' } as IEditing;
    private sentenceList: Iobject[] = [];

    async onCopyTemplate() {
        const el = document.querySelector('#autoCreateTemplate') as HTMLElement;
        if (!el) return;
        // copySelectElementContents(el);
        // Message.success('模板已复制到粘贴板~~');

        this.$emit('success', el.innerHTML, false);
    }

    handlerAutoNewsItemList(params: IAutoNewsParams) {
        request({
            url: '/api/article/news/session',
            method: IRequestMethod.POST,
            params,
        }).then((res: Iobject) => {
            this.pagination = {
                current: res.current + 1,
                pageSize: res.pageSize, // 无效参数
                total: 9999,
            };
            const AutoNewsSentenceIdList = this.autoNewsList.map((an: IAutoCreateSentenceItem) => an.sentenceId);
            const filterResData = res.data.filter((da: IAutoCreateSentenceItem) => {
                return !AutoNewsSentenceIdList.includes(da.sentenceId);
            });
            this.autoNewsList = this.autoNewsList.concat(filterResData);
        });
    }

    onDblClick(sen: IAutoCreateSentenceItem) {
        this.editing = { ...sen, canEdit: true, tmpValue: sen.title };
        this.showEditDialog = true;
    }

    onSubmitEditingImage() {
        this.editing.img = [this.editingImage];
        Message.success('图片添加成功');
    }
    onSubmitEdit() {
        this.autoNewsList = this.autoNewsList.map((s: IAutoCreateSentenceItem) => {
            if (s.sentenceId === this.editing.sentenceId) {
                s.title = this.editing.tmpValue;
                s.img = this.editing.img ?? [];

                this.onToggleNewsItem(s, true);
            }
            return s;
        });
        this.showEditDialog = false;
    }

    onToggleNewsItem(sen: IAutoCreateSentenceItem, checked = !sen.checked) {
        sen.checked = checked;

        const { origin, ...senNew } = sen;

        console.log(senNew, ',,,,,,,,,,', origin);

        request({
            url: '/api/article/news/session',
            method: IRequestMethod.POST,
            params: {
                newsName: date2name(this.newsName),
                data: [senNew],
            },
        });
    }

    beforeHandlerAutoNewsItemList() {
        this.handlerAutoNewsItemList({
            newsName: date2name(this.newsName),
            origin: true,
            current: this.pagination.current,
            type: 'tecent',
        });
    }

    async mounted() {
        await this.handlerAutoNewsItemList({
            newsName: date2name(this.newsName),
        });

        await request({
            url: '/api/article/sentence/list',
            method: IRequestMethod.GET,
            params: {
                pageSize: 200,
                current: 1,
                used: 0,
            },
        }).then((res: Iobject) => {
            this.sentenceList = res?.sentenceList ?? [];
        });
        this.beforeHandlerAutoNewsItemList();
    }

    get formatAutoNewsItemList() {
        const content = this.autoNewsList
            .filter((sen: IAutoCreateSentenceItem) => sen.checked)
            .map((sen: IAutoCreateSentenceItem) => {
                return `${sen.title}${sen.mediaName ? `（${sen.mediaName}）` : ''}\n${(sen?.description ?? []).join(
                    `DD/ \n`,
                )}${(sen?.img ?? []).map((i: string) => `DD/ ![](${i})`).join(`\n`)}\n`;
            })
            .join('\n## ');
        const idx = Math.floor(Math.random() * this.sentenceList.length);
        const curSentence = this.sentenceList?.[idx] || undefined;
        const senTemplate = curSentence
            ? `
ZZ/ ${curSentence?.sentence}
ZZ/ @@ ${curSentence?.series.join(' @@ ')} @@
`
            : 'ZZ/ ';

        return `
# 今日农历

HH/ ${getLunarDate(new Date(this.newsName), '农历YY年 MM月 DD')}
HH/ 宜：祭祀,修墳,涂泥,餘事勿取
HH/ 忌：移徙.入宅.嫁娶.开市.安葬

# 优秀精句

${senTemplate}

# 早报简讯

## ${content}`;
    }

    get checkedNewsCount() {
        return this.autoNewsList.filter((s: IAutoCreateSentenceItem) => s.checked).length;
    }
    get editingLink() {
        return `https://new.qq.com/rain/a/${this.editing?.origin?.cms_id}`;
    }
    get editingImage() {
        return this.editing?.origin?.thumb_nail_2x;
    }
}
